import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import '../styles.css';

const Base = ({ children }) => {
  const location = useLocation();

  const isActive = (path) => location.pathname === path;

  return (
    <div className="base">
      <header>
        <h1 style={{ letterSpacing: '0.2em', marginTop: 40 }}>RENALDI DESIGN</h1>
        <nav style={{ marginTop: 40 }}>
          <NavLink exact to="/" style={{ opacity: isActive('/') ? 0.5 : 1 }}>
            HOME
          </NavLink>
          <NavLink to="/about" style={{ opacity: isActive('/about') ? 0.5 : 1 }}>
            ABOUT
          </NavLink>
          <NavLink to="/contact" style={{ opacity: isActive('/contact') ? 0.5 : 1 }}>
            CONTACT
          </NavLink>
        </nav>
      </header>
      <main>{children}</main>
      <footer>
        <p>
          Art Renaldi AB | Kryptongatan 20, SE - 431 53 Mölndal | +46 704 37 89 10 |
          madeleine@artrenaldi.se
        </p>
        <p>© 2024 by Madeleine Englund</p>
      </footer>
    </div>
  );
};

export default Base;
